import styled from "styled-components";
import React from 'react'
import { ThemeProvider } from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Sidebar } from '../components/Sidebar';
import FooterContainer from '../containers/FooterContainer';
import { Header } from '../containers/HeaderContainer';
import useApp, { withAppContext } from '../contexts/context'
import { useSiteMetadata } from '../hooks/useQuerydata';
import { Button } from "../components/Button/Button";

const SEOData = [
  {
    "__typename": "PrismicBlogBody2GeneralCard",
    "primary": {
      "title": {
        "html": "<h1>Page not found - Draper Startup House</h1>",
        "text": "Page not found - Draper Startup House",
        "raw": [
          {
            "type": "heading1",
            "text": "Page not found - Draper Startup House",
            "spans": []
          }
        ]
      },
      "robot": {
        "html": "",
        "text": "",
        "raw": []
      },
      "image": {
        "url": null,
        "alt": null
      },
      "googlebot": {
        "html": "",
        "text": "",
        "raw": []
      },
      "favicon": {
        "alt": null,
        "url": "https://images.prismic.io/draper-startup-house/6b85d7f7-37d3-4dab-bcaf-9daa389b2c7b_favicon.png?auto=compress%2Cformat&rect=0%2C0%2C48%2C48&w=48&h=48"
      },
      "description": {
        "html": "",
        "text": "",
        "raw": []
      },
      "bingbot": {
        "html": "",
        "text": "",
        "raw": []
      }
    },
    "slice_type": "general_card"
  }
];
const NotFoundPage = () => {
  const { toggle, handleToggleLayout } = useApp();
  const { allPrismicFooter, allPrismicHeader } = useSiteMetadata();

  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const theme = {
    bgColor: 'white',
  }
  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer data={allPrismicFooter?.nodes[0]?.data} />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={
          <SEO data={SEOData} />
        }
      >
        <WrapperLayout>
          <H1>404</H1>
          <H2>Page Not Found</H2>
          <br />
          <ButtonBack
            onClick={() =>
              typeof window !== "undefined" && (window.location.href = '/')
            }
            type="primary"
          >Back home</ButtonBack>
        </WrapperLayout>
      </Layout>
    </ThemeProvider>
  )
}

export default withAppContext(NotFoundPage);
const WrapperLayout = styled.div`
  margin-bottom: 100px;
  overflow: hidden;
  margin-top: 90px !important;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
`;
const H1 = styled.h1`
  font-size: 250px;
  line-height: 250px;
`
const H2 = styled.h2`
  font-size: 54px;
  text-transform: uppercase;
`
const ButtonBack = styled(Button)`

`